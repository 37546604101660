<template>
    <div class="page-wrapper" id="scroll_top">
        <section class="hero_full" v-if="pageIsLoaded">
            <div class="title_wrap" data-inview="fadeInUp" data-delay="200">
                <h1 class="title medium"><span v-html="content.entete1.titre"></span></h1>
                <p class="regular-text">
                    <span v-html="content.entete1.texte"></span>
                </p>
            </div>
            <div class="img" :style="'background-image: url(' + content.imageSimple2.url + ')'">
                <a href="#next" v-scroll-to:-120 class="round">
                    <svg
                        fill="none"
                        class="arrow"
                        viewBox="0 0 13 26"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="m11.6412 17.9322-4.17083 4.2832v-22.2154h-1.94081v22.2154l-4.17082-4.2832-1.35874 1.395 6.5 6.6728 6.5-6.6728z"
                        />
                    </svg>
                </a>
            </div>
        </section>

        <section class="txt_row" id="next" v-if="pageIsLoaded">
            <div
                class="row"
                data-inview="fadeInUp"
                data-delay="200"
                v-for="(item, i) in content.listeInformations1"
                :key="i"
            >
                <h4 class="title medium">{{ item.titre }}</h4>
                <p class="regular-text">
                    <span v-html="item.texte"></span>
                </p>
            </div>
        </section>

        <section class="content_img_wrap" v-if="pageIsLoaded">
            <div
                class="img"
                data-inview="fadeInUp"
                data-delay="200"
                v-for="(item, i) in content.images"
                :key="i"
            >
                <img :src="item.url" :alt="item.titre" />
            </div>
        </section>

        <section class="project_listing" v-if="pageIsLoaded && realisationsChunks.length">
            <div class="title_wrap left" data-inview="fadeInUp" data-delay="200">
                <h2 class="title small" data-inview="fadeInUp" data-delay="200">
                    {{ content.entete2.surtitre }}
                </h2>
                <h3 class="title medium" data-inview="fadeInUp" data-delay="300">
                    <span v-html="content.entete2.titre"></span>
                </h3>
            </div>
            <div class="row">
                <router-link
                    :to="item.page.jsonUrl"
                    class="project"
                    data-inview="fadeInUp"
                    :data-delay="2 * 1 + '00'"
                    v-for="(item, i) in realisationsChunks[0]"
                    :key="i"
                >
                    <div class="img">
                        <div class="more">
                            <p class="regular-text">{{ $t('En savoir plus') }}</p>
                        </div>
                        <img
                            :src="item.page.imageSimple1.url"
                            :alt="item.page.imageSimple1.titre"
                        />
                    </div>
                    <div class="data">
                        <p class="title">{{ item.page.titre }}</p>
                    </div>
                </router-link>
            </div>
            <div class="row">
                <router-link
                    :to="item.page.jsonUrl"
                    class="project"
                    data-inview="fadeInUp"
                    :data-delay="2 * 1 + '00'"
                    v-for="(item, i) in realisationsChunks[1]"
                    :key="i"
                >
                    <div class="img">
                        <div class="more">
                            <p class="regular-text">{{ $t('En savoir plus') }}</p>
                        </div>
                        <img
                            :src="item.page.imageSimple1.url"
                            :alt="item.page.imageSimple1.titre"
                        />
                    </div>
                    <div class="data">
                        <p class="title">{{ item.page.titre }}</p>
                    </div>
                </router-link>
            </div>
            <div class="cta_wrap" data-inview="fadeInUp" data-delay="200">
                <router-link :to="{ name: 'Realisations-' + $route.meta.lang }" class="cta">
                    <p class="regular-text">{{ $t('Découvrez nos réalisations') }}</p>
                    <img src="@/assets/img/arrow-right.svg" alt="arrow right" />
                </router-link>
            </div>
        </section>

        <section class="other_wrap" v-if="pageIsLoaded">
            <div class="title_wrap left" data-inview="fadeInUp" data-delay="200">
                <h2 class="title small">{{ content.entete3.surtitre }}</h2>
                <h3 class="title medium">
                    <span v-html="content.entete3.titre"></span>
                </h3>
            </div>
            <router-link
                :to="item.page.jsonUrl"
                class="box"
                data-inview="fadeInUp"
                data-delay="200"
                v-for="(item, i) in relatedServices"
                :key="i"
            >
                <div class="content">
                    <p class="regular-text caps">{{ item.page.titre }}</p>
                    <p class="regular-text">
                        <span v-html="item.page.texteSimple1"></span>
                    </p>
                    <div class="cta">
                        <p class="regular-text">{{ $t('Informations') }}</p>
                        <img src="@/assets/img/arrow-right.svg" alt="arrow right" />
                    </div>
                </div>
                <img :src="item.page.imageSimple1.url" alt="Génie civil" />
            </router-link>
        </section>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import GLightbox from 'glightbox'
// import Flickity from 'flickity'

export default {
    data() {
        return {
            lightboxelement: null,
        }
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        this.lightboxelement = GLightbox
                        GLightbox()

                        setTimeout(() => {
                            // this.teamCarousel.resize()
                        }, 1755)
                    }, 1055)
                }
            },
            immediate: true,
        },
    },

    computed: {
        realisationsChunks() {
            if (!this.pageIsLoaded) {
                return [[], []]
            }
            // Take 4 items from realisationsEntry data array
            let realisationsArray = this.globals.realisationsEntry.data.filter(
                i => i.page.choixDeServicesId === this.content.id
            )
            realisationsArray = realisationsArray.slice(0, 4)
            // Split the realisations array in two chunks of 2
            return realisationsArray.reduce((acc, item, index) => {
                if (index % 2 === 0) {
                    acc.push([item])
                } else {
                    acc[acc.length - 1].push(item)
                }
                return acc
            }, [])
        },
        relatedServices() {
            if (!this.globalsIsLoaded) {
                return []
            }

            // Return servicesEntry where ID is not current service ID
            return this.globals.servicesEntry.data.filter(
                service => service.page.id !== this.content.id
            )
        },
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>

<style lang="scss" scoped></style>
